import { Controller } from '@hotwired/stimulus'
import { debounce } from '../scripts/debounce'

export default class extends Controller {
    static targets = ['article', 'search', 'submenu', 'filter']

    searchTargetConnected(input) {
        const debounceKeydown = debounce(() => {
            input.form.requestSubmit();
            input.disabled = true;
            input.form.classList.add('submitting');
        }, 1000)

        input.addEventListener('keydown', () => {
            debounceKeydown();
        });

        input.focus();
        input.selectionStart = input.selectionEnd = input.value.length;
    }

    submenuTargetConnected(submenu) {
        submenu.addEventListener('click', (e) => {
            e.preventDefault();
            submenu.classList.toggle('open');
            return false;
        });
    }

    filterTargetConnected(filter) {
        filter.addEventListener('click', (e) => {
            if (null !== e.target.closest('.newsfilter__filter') && null === e.target.closest('.newsfilter__link--sub')) {
                if (filter.classList.contains('dropdown-open')) {
                    filter.classList.remove('dropdown-open');
                } else {
                    filter.classList.add('dropdown-open');
                }
            }
        });

        if (filter.getElementsByClassName('newsfilter__selected')[0]) {
            filter.classList.add('newsfilter__filter--active');
        }
    }
}
